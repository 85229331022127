.dash-back {
  background-color: var(--keza-dashboard);
  position: relative;
  max-width: 100vw;
  overflow: hidden;
  min-height: 100vh;
}

.dash-child {
  position: relative;
  background-color: white;
  border-radius: 30px 0 0 0;
  padding: 2rem;
  flex: 1;
  min-height: 90vh;
  margin-left: 225px;
  /* border-right: 20px solid black; */
  max-width: 90%;
}
.nav-item a {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.3rem 1.3rem;
  width: 14rem;
}
.nav-item .active-link {
  background-color: white;
  color: var(--keza-brown);
  border-right: 3px solid var(--keza-brown);
}
.sb-nav-wrapper ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
}

.so-nav {
  margin-top: 7rem;
}

.nbt500 {
  font-family: "BR Firma";
  color: #1d1d1d;
  font-size: 26px;
  font-weight: 400;
}
.nbt16 {
  font-size: 16px;
}
.mobile-men {
  display: none;
}
.input-field-dash {
  display: flex;
  flex-direction: column;
  width: 45%;
  position: relative;
}

.input-field-dash input {
  border: 0.663653px solid rgba(170, 68, 40, 0.5);
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  outline: none;
  padding: 0 1rem;
  background: transparent;
}

/* ============label ============= */
.input-field-dash label {
  display: flex;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 0.7rem;
  gap: 0.3rem;
}

.input-field-dash label img {
  object-fit: contain;
  align-self: start;
}

.dashboard-select__value-container {
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  outline: none;
  padding: 0 1rem;
  background: transparent;
}

@media only screen and (max-width: 767.8px) {
  .dash-child {
    position: relative;
    border-radius: 0;
    padding: 1rem;
    flex: 1 1 100%;
    min-height: 90vh;
    margin-left: 0;
    max-width: 100%;
  }
  .dash-back {
    background-color: rgb(255, 255, 255);
    /* position: static; */
  }
  .input-field-dash {
    width: 100%;
  }
  .input-field-dash input {
    height: 2.5rem;
    font-size: 16px;
  }
  .dashboard-select__value-container {
    height: 2.5rem;
    font-size: 16px !important;
  }
  .dashboard-select__option {
    font-size: 16px !important;
  }
  .nbt500 {
    font-size: 16px;
  }
  .input-field-dash label {
    font-size: 13px;
  }
  .mobile-men {
    width: 100vw;
    height: 100vh;
    background-color: var(--keza-dashboard);
    position: fixed;
    z-index: 2;
    display: block;
    padding-top: 2rem;
    top: 4rem;
  }
  .mob-head-wrapp {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 2;
    display: block;
    background-color: red;
  }
  .sb-nav-wrapper ul {
    align-items: center;
    width: 100%;
  }
  .nav-item,
  .sb-nav-wrapper {
    width: 100%;
  }
  .nav-item a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5rem;
  }
  .nbt16 {
    font-size: 14px;
  }
}
