/* =====tabs */
.st-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.sig-her {
  /* width: 100%;
  height: 100%; */
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../../assets/auth/Frame\ 648signup.png");
}

.tab-circle {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}
.steps {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 70%;
  justify-content: center;
}

.steps-description {
  position: absolute;
  top: 3rem;
  width: 14rem;
  text-align: center;
}
.small-txt {
  font-size: 11px;
  margin-top: 0.5rem;
}

/* ==============Open camera ============ */

.open-icon {
  cursor: pointer;
}
@media screen and (max-width: 540px) {
  .tab-circle {
    width: 1.8rem;
    min-width: 1.8rem;
    height: 1.8rem;

    font-size: 0.8rem;
    font-weight: 600;
  }
  .steps {
    max-width: 90%;
  }
  .steps-description {
    font-size: 14px;
    top: 2.5rem;
  }

  .right-form-v2 {
    padding-bottom: 1rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 541px) and (max-width: 771px) {
  .form-v2 {
  }
  .left-form-v2 {
    display: none;
  }
  .right-form-v2 {
    padding-bottom: 1rem;
    width: 95%;
  }
}
