.ant-space-item {
  width: 100%;
}

.pr-8 {
  padding-right: 8px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-8 {
  padding-left: 8px;
}

.border-bottom-only {
  border: none !important;
  border-bottom: 2px solid #d9d9d9 !important;
  outline: none !important;
}

.border-bottom-only:focus {
  outline: none !important;
}

body {
  overflow-x: hidden;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
}
.cursor-pointer {
  cursor: pointer;
}

.keza-btn-primary {
  display: block !important;
  font-weight: 400 !important;
  font-size: var(--keza-font-14);
  width: 10.5rem !important;
  height: 2.5rem !important;
  color: var(--keza-white) !important;
  background: var(--keza-brown) !important;
  border-radius: 3px !important;
  border-color: var(--keza-brown) !important;
  margin: 1rem auto 1rem auto !important;
}

.section-header {
  background-color: #fff2df;
  padding: 8px 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--keza-black);
}

.img-thumb {
  width: 150px;
  object-fit: contain;
  cursor: pointer;
}

.ant-tabs {
  flex: 1 !important;
}

.pricing-table {
  width: 100%;
  min-height: 100px;
}

.pricing-table tr td {
  margin-bottom: 10px;
}

.upload-file-doc-img {
  width: 100%;
  object-fit: contain;
  height: 100px;
}

.center-form {
  width: 70%;
  max-width: 600px;
  margin: 0 auto;
}

/*---------*/

/*---------*/
.request-dash {
  display: grid;
  grid-template-columns: 70% 22%;
  gap: 30px;
  padding: 20px 10px;
}
.card-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}
.r-table h2 {
  font-size: var(--keza-font-18) !important;
  font-weight: 500;
  line-height: 23px;
}
.b-card {
  background: #5a5b5f;
  border-radius: 10px;
  padding: 15px;
  width: 300px;
  margin: 0 auto;
}
.b-card-cont {
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.b-card-cont span {
  font-size: var(--keza-font-14) !important;
  font-weight: 500;
  line-height: 18px;
  opacity: 0.6;
  color: var(--keza-white);
}
.b-card-value {
  gap: 5px;
  justify-items: center;
  align-items: center;
}
.b-card-value h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  color: var(--keza-white);
}
.b-card-value span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2.81088px 8.43265px;
  gap: 7.03px;
  background: rgba(35, 169, 73, 0.2);
  border-radius: 11.2435px;
}
.card-btn-alt {
  margin: auto;
  background: transparent;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  border: 0.5px solid rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  font-size: var(--keza-font-14);
  color: var(--keza-white) !important;
}
.action-btn {
  margin: auto;
  background: transparent;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  border: 0.5px solid rgba(29, 29, 29, 0.7);
  border-radius: 30px;
  font-size: var(--keza-font-14);
  color: var(--keza-black) !important;
  opacity: 0.7;
}

/*----d-card-----*/
.r-right {
  flex-direction: column;
  gap: 20px;
}
.d-card {
  border: 0.5px solid rgba(29, 29, 29, 0.5);
  padding: 20px;
  gap: 10px;
  border-radius: 5px;
  width: 300px;
  margin: 0 auto;
  flex-direction: column;
}
.d-card h2 {
  font-size: var(--keza-font-16) !important;
  color: var(--keza-black) !important;
  font-weight: 700;
  line-height: 23px;
  font-family: "BR Firma";
}

.d-card span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  gap: 4px;
  background: #ffe39b;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--keza-black);
  opacity: 0.5;
}

/*----product add-----*/
.prodcutAdd {
  background: rgba(207, 224, 235, 0.4);
  border-radius: 10px;
  width: 100%;
}
.add-info {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.add-info p {
  font-size: var(--keza-font-18) !important;
  color: var(--keza-brown) !important;
  font-weight: 700;
  line-height: 23px;
  font-family: "BR Firma";
}
.add-info p pre {
  font-size: var(--keza-font-18) !important;
  color: var(--keza-brown) !important;
  font-weight: 700;
  line-height: 23px;
  font-family: "BR Firma";
}
.add-btn {
  margin-left: auto !important;
  padding: 10px 20px !important;
  gap: 5px;
  font-size: var(--keza-font-14);
  color: var(--keza-brown) !important;
  border: 1px solid #aa4428;
  border-radius: 30px;
  width: 130px;
  height: 40px;
  background: transparent;
}

@media screen and (max-width: 540px) {
  .add-info {
    padding: 10px 10px;
    gap: 10px;
    flex-direction: column !important;
  }
  .add-btn {
    margin: 0 auto !important;
  }
  .card-grid {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 30px;
    padding: 20px 0px;
  }
  .r-right {
    flex-direction: column !important;
    gap: 30px;
  }
  .add-info p {
    font-size: 12px !important;
    text-align: center;
  }
}

@media screen and (max-width: 760px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px 0px;
  }
  .add-info {
    padding: 20px 10px;
  }
  .add-btn {
    width: 130px;
    height: 30px;
    font-size: 10px;
  }
  .prodcutAdd img {
    display: none;
  }
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 1250px) {
  .request-dash {
    grid-template-columns: 100%;
    gap: 40px;
    padding: 20px 10px;
  }
  .d-card {
    width: 300px;
    margin: 0 auto;
  }
  .r-right {
    flex-direction: row;
    gap: 30px;
  }
}

.pag-num {
  border: 2px solid #aa4428;
  border-radius: 8px;
  color: var(--keza-brown);
  font-size: var(--keza-font-24);
  font-weight: 700;
  gap: 1rem;
  height: 3rem;
  width: 6.6rem;
}

.go-left {
  background: transparent !important;
  border: 1px solid var(--keza-brown);
  border-radius: 5px;
  cursor: pointer;
  height: 2.5rem;
  padding: 0;
  width: 2.7rem;
}

.go-left,
.pag-num {
  align-items: center;
  display: flex;
  justify-content: center;
}

.modal-backdrop.show-overlay {
  background-color: #000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 1040;
}

.modal-backdrop.show-overlay.show {
  opacity: 0.5;
  visibility: visible;
}
