@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@font-face {
  font-family: "BR Firma";
  src: url("../../src/font/BR Firma/BR\ Firma\ Regular.otf");
}

@font-face {
  font-family: "Calling Code";
  src: url("../../src/font/Calling Code/CallingCode-Regular.otf");
}

.html {
  scroll-behavior: smooth;
}

:root {
  --keza-sidebar: #ffd3aa;
  --keza-brown: #aa4428;
  --keza-dashboard: #ffd3aa;

  --keza-black: #1d1d1d;
  --keza-white: #ffffff;
  --keza-white2: #cfe0eb66;
  --keza-grey: #5a5b5f;

  --desktop-width: 84%;
  --keza-font-14: 0.7rem;
  --keza-font-16: 0.8rem;
  --keza-font-18: 1rem;
  --keza-font-20: 1.25rem;
  --keza-font-22: 1.375rem;
  --keza-font-24: 1.5rem;
  --keza-font-28: 1.75rem;
  --keza-font-48: 3rem;
  --keza-font-50: 3.125rem;
  --keza-font-64: 4rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  scroll-behavior: smooth;
  background-color: white;
  color: var(--keza-black);
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--keza-black);
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  display: block;
}

a {
  text-decoration: none;
  color: var(--keza-black);
}

button {
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: var(--keza-font-14);
  outline: none;
  border: none;
}

.section-h {
  font-family: "BR Firma", sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: var(--keza-font-50);
}

.section-sub-h {
  text-align: center;
  font-weight: 400;
  font-size: var(--keza-font-24);
}

.prices {
  font-weight: 400;
  margin-top: 20px;
}

.prices > :nth-child(2) {
  font-size: var(--keza-font-28);
  font-weight: 700;
  margin: 0px 0.12rem;
}

.form-divider {
  opacity: 0.2;
  height: 1px;
  max-width: 80%;
  margin: 2rem auto;
  background-color: var(--keza-black);
}
.small-txt{
  color: red;
}

/* ================================================ */
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
}
.modal-backdrop.show {
  visibility: visible;
  opacity: 1.5;
}

.modal-backdrop.lazy {
  position: relative;
  background-color: unset;
}
@media screen and (max-width: 540px) {
  :root {
    --keza-font-14: 0.5rem !important;
    --keza-font-16: 0.5rem !important;
    --keza-font-18: 0.6rem !important;
    --keza-font-20: 0.7rem !important;
    --keza-font-22: 0.75rem !important;
    --keza-font-24: 0.8rem !important;
    --keza-font-28: 0.9rem !important;
    --keza-font-48: 1.7rem;
    --keza-font-50: 1.9rem;
    --keza-font-64: 2.2rem;
  }

  .section-h {
    font-family: "BR Firma", sans-serif;
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
  }

  .section-sub-h {
    text-align: center;
    font-weight: 400;
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 960px) {
  :root {
    --keza-font-14: 0.6rem !important;
    --keza-font-16: 0.6rem !important;
    --keza-font-18: 0.8rem !important;
    --keza-font-20: 0.9rem !important;
    --keza-font-22: 1rem !important;
    --keza-font-24: 1.25rem !important;
    --keza-font-28: 1.5rem !important;
    --keza-font-48: 1.8rem;
    --keza-font-50: 2rem;
    --keza-font-64: 2.5rem;
  }
}

@media screen and (max-width: 1247px) {
  :root {
    --keza-font-14: 0.7rem;
    --keza-font-16: 0.7rem;
    --keza-font-18: 0.8rem;
    --keza-font-20: 1rem;
    --keza-font-22: 1.25rem;
    --keza-font-24: 1.375rem;
    --keza-font-28: 1.5rem;
    --keza-font-48: 2.5rem;
    --keza-font-50: 3rem;
    --keza-font-64: 3.5rem;
  }
}
